import React from "react";
import { Container, Row } from "react-bootstrap";
// import {AiFillGithub} from "react-icons/ai";

function Footer() {
//   let date = new Date();
//   let annee = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
      </Row>
    </Container>
  );
}

export default Footer;
